import CoreuiVue from '@coreui/vue'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { createApp } from 'vue'
import { VueFire, VueFireAppCheck, VueFireAuth } from 'vuefire'
import { createHead } from '@unhead/vue/client'
import { initMixpanel } from '@/services/analyticsService'
import firebaseConfig from '@/services/firebaseConfig'
import {
  AppCheckProvider,
  initializeFirestoreDb,
} from '@/services/firebaseService'
import { initSentry } from '@/services/sentryService'
import { initSmartlook } from '@/services/smartlookService'
import store from './store'
import router from './router'
import App from './App.vue'
// Import CoreUI icons
import { CIcon } from '@coreui/icons-vue'
// Import free icons only - flag and brand icons will be loaded on demand
import * as freeSet from '@coreui/icons/dist/esm/free'

export const app = createApp(App)
initMixpanel()
initSentry(app, router)

// Check if Firebase app is already initialized
if (!getApps().length) {
  initializeApp(firebaseConfig)
}

;(async () => { await initSmartlook() })()

// Initialize Firestore

initializeFirestoreDb()

app.use(VueFire, {
  firebaseApp: getApp(),
  modules: [VueFireAppCheck({ provider: AppCheckProvider }), VueFireAuth()],
})

app.use(store)

app.use(router)
const head = createHead()
app.use(head)
app.use(CoreuiVue)

// Register CoreUI icons (only free icons are loaded eagerly)
app.provide('icons', {
  // Brand icons will be dynamically imported when needed
  brandSet: {},
  freeSet,
  // Flag icons will be dynamically imported when needed
  flagSet: {}
})
app.component('CIcon', CIcon)

app.mount('#app')
